.button:hover {
	background-color: #17b5e5 !important;
	color: #ffffff !important;
}

.contact-spacer {
	height: 700px;
}

.message-box-container {
	max-width: 450px;
	min-width: 400px;
	padding-right: 20px;
	padding-left: 20px;
	flex: 1;
}

@media screen and (max-width: 650px) {
	.message-box-container {
		padding: 0 !important;
		min-width: 0 !important;
	}
	.message-wrapper {
		flex-direction: column !important;
		gap: 25px !important;
	}
	.more-info {
		margin-top: 0 !important;
		padding-left: 25px;
		padding-right: 25px;
		padding-bottom: 25px;
	}
	.message-container {
		margin-bottom: 0 !important;
	}
	.contact-spacer {
		height: 1175px !important;
	}
	.message-box {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}
}

@media screen and (max-width: 960px) {
	.faq-wrapper {
		flex-direction: column !important;
	}
}
