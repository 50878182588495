* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

@font-face {
	font-family: "Montserrat";
	src: url("./assets/fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
}

@font-face {
	font-family: "Bebas Neue";
	src: url("./assets/fonts/BebasNeue-Regular.ttf") format("truetype");
}

body {
	margin: 0;
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: "Montserrat", sans-serif;
}

input,
textarea {
	outline: 0;
	border-radius: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	appearance: none;
	margin: 0;
}

.hidden-scroll {
	overflow: hidden;
}

.scrollable-content {
	overflow-x: hidden;
}
.scrollable-content::-webkit-scrollbar {
	width: 10px !important;
}
.scrollable-content::-webkit-scrollbar * {
	background: transparent;
}
.scrollable-content::-webkit-scrollbar-thumb {
	background: #c1c1c1 !important;
	width: 5px !important;
}
.single-only {
	margin-bottom: 50px;
}

@media screen and (max-width: 950px) {
	.hero-image {
		width: 100% !important;
		height: 300px !important;
		min-width: min-content !important;
	}
}

@media screen and (max-width: 500px) {
	.hero-image {
		height: 200px !important;
	}
}

@media screen and (max-width: 500px) {
	.block {
		width: 100% !important;
		left: 0 !important;
	}
	.top-container {
		padding: 50px 0 50px 0 !important;
	}
	.hero-container {
		padding: 0 !important;
	}
}

@media screen and (max-width: 520px) {
	.right-preview {
		display: none !important;
	}
	.left-preview {
		width: 100% !important;
	}
	.left-preview span {
		min-width: 100% !important;
	}
}

@media screen and (min-width: 521px) {
	.single-only {
		display: none;
	}
}

@media screen and (max-width: 800px) {
	.service-container {
		flex-direction: column-reverse !important;
		margin-left: 20px !important;
		margin-right: 20px !important;
	}
	.service-text-container {
		width: 100% !important;
		padding: 50px 50px !important;
		display: flex !important;
		justify-content: space-between !important;
		gap: 20px !important;
		max-width: none !important;
		flex-direction: column !important;
	}
	.service-image-container {
		height: 100% !important;
		min-height: 200px;
	}
}
@media screen and (max-width: 500px) {
	.tagline {
		font-size: 36px !important;
	}
	.header-description {
		font-size: 21px !important;
	}
	.service-text-container {
		padding: 50px 20px !important;
	}
	.footer-container {
		padding: 0 !important;
	}
	.boulder-image {
		height: 70px;
	}
}
