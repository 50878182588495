.link {
	color: #ffffff;
	font-size: 500;
	text-decoration: none;
	padding: 20px;
	letter-spacing: -0.1;
}

.link:hover {
	color: #000000;
}

.mobileLink {
	display: flex;
	color: #17b5e5;
	border-bottom: 2px solid #17b5e5;
	width: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
	padding: 25px 0;
	font-family: "Bebas Neue";
	font-size: 36px;
	text-align: center;
}

.mobile-menu {
	position: fixed !important;
	top: 0 !important;
	right: -100% !important;
	width: 100% !important;
	height: 100% !important;
	transition: right 0.3s ease, background-color 0.1s ease;
	z-index: 1000 !important;
	background-color: rgba(0, 0, 0, 0);
}

.mobile-menu.open {
	right: 0 !important;
	background-color: rgba(0, 0, 0, 0.5) !important;
}

@media screen and (max-width: 400px) {
	.facebook {
		display: none !important;
	}
}

@media screen and (max-width: 340px) {
	.instagram {
		display: none !important;
	}
}
