.image-carousel {
	margin-top: 50px;
	flex-grow: 1;
	max-width: 1400px;
}
.arrow div {
	user-select: none;
}
.arrow:hover {
	background-color: #17b5e5 !important;
}
